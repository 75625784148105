import React from "react";
import QuickView from "./QuickView/QuickView";
import DeviceContext, { BuyBoxConfig, DeviceContextI } from "src/contexts/DeviceContext";
import { getThemeName, Theme } from "src/utils/themeUtils";
import { getCurrentLocale } from "src/utils/localeUtils";
import { getDeviceDetails, SURFACE_TYPE } from "src/utils/deviceUtils";
import Translations from "./Translations/Translations";
import debug from "src/utils/debugUtils";
import { disableMetrics, setQvSessionId } from "src/utils/metricsUtils";
import { setRequestDomain, setUseRequestDomainOnRequests } from "src/utils/ajaxUtils";
import { getSurfaceType } from "src/utils/deviceUtils"
import { uuidv4 } from "src/utils/uuid";
import { getUrlHashParams } from "src/utils/urlUtils";
import BuyBoxOnly from "./QuickView/BuyBoxOnly";

type PropTypes = object;

let localeChangeCallback: (locale: string) => void | undefined;
const onLocaleChange = (callback: (locale: string) => void) => {
    localeChangeCallback = callback;
};

window.addEventListener("languagechange", () => {
    if (typeof localeChangeCallback === "function") {
        localeChangeCallback(navigator.language);
    }
});

let themeChangeCallback: (theme: Theme) => void | undefined;
const onThemeChange = (callback: (theme: Theme) => void) => {
    themeChangeCallback = callback;
};

window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
    if (typeof themeChangeCallback === "function") {
        const theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.DARK : Theme.LIGHT;
        themeChangeCallback(theme);
    }
});

let visibilityChangedCallback: (focused: boolean) => void | undefined;
const onVisibilityChange = (callback: (isVisible: boolean) => void) => {
    visibilityChangedCallback = callback;
};

window.addEventListener("visibilitychange", () => {
    if (typeof visibilityChangedCallback === "function") {
        visibilityChangedCallback(document.visibilityState === 'visible');
    }
});

const purchaseFlowEligibleSurfaceTypes = [
    SURFACE_TYPE.androidMobile,
    SURFACE_TYPE.androidTablet,
];

const purchaseFlowElgibleDomains = [
    "amazon.com",
];

const getDomain = () => {
    const domain = window.quickViewData?.domain || window.location.host.replace(/.*amazon/i, "amazon");
    if (domain.startsWith("amazon.") && !domain.endsWith("dev")) {
        return domain.toLowerCase();
    } else {
        return "amazon.com";
    }
};

const mode = getUrlHashParams().mode; // ?? "buyBox";

const getBuyBoxConfig = (treatment: string): BuyBoxConfig => {
    return {
        enabled: ["T1", "T2", "T3", "T4", "T5", "T6"].includes(treatment),
        inlined: ["T5", "T6"].includes(treatment) || debug.get("buyBox.forceInlined"),
        enableMes: ["T2", "T4", "T6"].includes(treatment) || debug.get("buyBox.forceMes"),
        useGetThisBook: ["T3", "T4"].includes(treatment) || debug.get("buyBox.forceUseGetThisBook"),
    };
};

export class App extends React.Component<PropTypes, DeviceContextI> {
    constructor(props: PropTypes) {
        super(props);

        if (!window.quickViewData && window.QuickView?.getQuickViewData) {
            window.quickViewData = JSON.parse(window.QuickView.getQuickViewData());
        }

        const fullUrlsSupported = window.quickViewData?.experimentalFeatureMap?.aapiAjaxUrlsEnabled === true
                               || window.quickViewData?.experimentalFeatures?.aapiAjaxUrlsEnabled === true;
        setUseRequestDomainOnRequests(fullUrlsSupported);

        if (debug.get("disableMetrics")) {
            disableMetrics();
        }

        const domain = getDomain();
        setRequestDomain(domain);
        setQvSessionId(`${getSurfaceType()}.${debug.versionInfo.buildDate}.${debug.versionInfo.mainJs}.${domain}.${uuidv4()}`);

        const buyBoxTreatment = window.quickViewData?.experimentalFeatures?.treatmentMap?.["KINDLE_QV_ANDROID_BUY_BOX_1090668"] ?? "C";
        debug.log(`buyBoxTreatment: ${buyBoxTreatment}`);

        const buyBoxConfig: BuyBoxConfig = getBuyBoxConfig(buyBoxTreatment);
        const deviceDetails = getDeviceDetails();
        const isPurchaseFlowEligible = purchaseFlowElgibleDomains.includes(domain) && purchaseFlowEligibleSurfaceTypes.includes(deviceDetails.surfaceType);
        buyBoxConfig.enabled = (isPurchaseFlowEligible && buyBoxConfig.enabled) || debug.get("forcePurchaseFlowEligible");

        this.state = {
            theme: getThemeName() as Theme,
            locale: getCurrentLocale(),
            device: deviceDetails,
            domain: domain,
            isVisible: document.visibilityState === 'visible',
            isFullUrlSupported: fullUrlsSupported,
            buyBox: buyBoxConfig,
            debugFlagsTimestamp: Date.now(),
        };
        debug.log(buyBoxConfig);
        debug.registerListener(() => {
            debug.log("debug.listener");
            const buyBoxConfig = getBuyBoxConfig(buyBoxTreatment);
            buyBoxConfig.enabled = (isPurchaseFlowEligible && buyBoxConfig.enabled) || debug.get("forcePurchaseFlowEligible");
            this.setState({
                buyBox: buyBoxConfig,
                debugFlagsTimestamp: Date.now(),
            });
        });
        onLocaleChange(this.onLocaleChangeCallback);
        onThemeChange(this.onThemeChangeCallback);
        onVisibilityChange(this.onVisibilityChangeCallback);
    }

    onLocaleChangeCallback = (locale: string) => {
        if (locale && locale !== this.state.locale) {
            this.setState({ locale });
        }
    };

    onThemeChangeCallback = (theme: Theme) => {
        if (theme !== this.state.theme) {
            this.setState({ theme });
        }
    };

    onVisibilityChangeCallback = (isVisible: boolean) => {
        if (isVisible !== this.state.isVisible) {
            this.setState({ isVisible });
        }
    };

    render() {
        return (
            <DeviceContext.Provider value={this.state}>
                <Translations locale={this.state.locale}>
                    {mode === "buyBox"
                        ? (<BuyBoxOnly />)
                        : (<QuickView />)
                    }
                </Translations>
            </DeviceContext.Provider>
        );
    }
}

export default App;
