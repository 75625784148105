import React, { useContext } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { NativeAPI } from "src/utils/deviceUtils";

type LinkProps = { href: string; };
const Link: React.FC<LinkProps> = ({ href, children }) => {
    return (<span className={styles.activeText} onClick={() => NativeAPI.openWebPage(href)}>
        {children}
    </span>);
};

export const PointsDetailsInfo: React.FC = () => {
    const deviceContext = useContext(DeviceContext);

    // TODO: Figure out a clean way to get the translations for this UI. AST has translations with embedded markup (gross) but having over a dozen discrete entries is also gross.
    if (deviceContext.locale.startsWith("es")) {
        return (<>
            <h3>Gana puntos Kindle al comprar libros. Canjea por descuentos en libros Kindle.</h3>
            ¿Cómo funciona?
            <br/><br/>
            1. <b>Gana puntos Kindle.</b> Consigue 3 puntos por cada dólar que gastes en eBooks Kindle y 1 punto por cada dólar que gastes en libros impresos. Consulta tus puntos en la <Link href="/-/es/kindlerewards">página de inicio de Kindle Rewards.</Link>
            <br/><br/>
            2. <b>Completa las bonificaciones.</b> ¡Consigue aún más puntos Kindle!
            <br/><br/>
            3. <b>Canjea por descuentos en libros Kindle.</b> Consigue 3 USD por cada 300 puntos.
            <br/><br/>
            <Link href="/-/es/kindlerewards?ref_=kr_krb_dp_bb_lm">Más información.</Link>
            <br/><br/>
            <Link href="/-/es/gp/help/customer/display.html?nodeId=Te7dElFGVJ5mGlAR0j">Sujeto a términos y condiciones.</Link>
        </>);
    }

    return (<>
         <h3>Earn Kindle Points when you buy books. Redeem for Kindle book savings.</h3>
         How it works
         <br/><br/>
         1. <b>Earn Kindle Points.</b> Get 3 points per dollar spent on Kindle books and 1 point per dollar spent on print books. Track your points on <Link href="/kindlerewards">Kindle Rewards home.</Link>
         <br/><br/>
         2. <b>Complete bonus offers.</b> Get even more Kindle Points!
         <br/><br/>
         3. <b>Redeem for Kindle book savings.</b> Get $3 for every 300 points.
         <br/><br/>
         <Link href="/kindlerewards?ref_=kr_krb_dp_bb_lm">Learn more.</Link>
         <br/><br/>
         <Link href="/gp/help/customer/display.html?nodeId=Te7dElFGVJ5mGlAR0j">Terms and conditions apply.</Link>
    </>);
};
