import React, { useContext } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";

export const AudibleInfo: React.FC = () => {
    const deviceContext = useContext(DeviceContext);

    // TODO: Figure out a clean way to get the translations for this UI. AST has translations with embedded markup (gross) but having over a dozen discrete entries is also gross.
    if (deviceContext.locale.startsWith("es")) {
        return (<>
            <h3 className={styles.keepItGoing}>Continúa la historia</h3>

            <div className={styles.narrationImage}>
                <img src="https://m.media-amazon.com/images/G/01/kindle/merch/global/narration-modal-devices._CB485979979_.png" />
            </div>

            <p><span className={styles.textHighlight}>Alterna</span> entre leer el libro Kindle y escuchar el audiolibro de Audible sin perder el hilo con Whispersync for Voice.</p>
            <p><span className={styles.textHighlight}>Sincroniza</span> tu libro en diferentes dispositivos y continúa siempre donde quedaste.</p>
            <p><span className={styles.textHighlight}>Ahorra</span> en el audiolibro de Audible y consigue un precio reducido con el libro Kindle.</p>
            <p><span className={styles.textHighlight}>Escucha</span> con la app de Kindle (iOS/Android) al realizar la compra.
                <br/>
                También puedes escuchar en tablets Fire, Kindle Touch, Kindle Keyboard, e-readers Kindle habilitados para Bluetooth y la app de Audible (iOS/Android/Windows).
            </p>

            <h4>¿Qué es un audiolibro de Audible?</h4>
            <p>Un audiolibro es una versión de audio de tu libro proporcionada por Audible.</p>
            <p>Con los audiolibros, puedes seguir disfrutando de un libro mientras viajas, haces tareas o entrenas; podrás escucharlos siempre que tus ojos estén ocupados pero tu mente esté libre.</p>

            <hr/>
            <h4>¿Cuáles son las ventajas de agregar un audiolibro Audible a mis eBooks de Kindle?</h4>
            <p>Puedes pasar de la lectura a la escucha sin problemas: tu lugar se guarda automáticamente, incluso cuando cambias de dispositivo. Escuchar una historia puede hacer que ésta cobre vida, puede brindar luz sobre los personajes y hacer que te sumerjas aún más en los libros que amas.</p>

            <hr/>
            <h4>¿Cómo lo activo en mi dispositivo?</h4>
            <p>No se requiere activación. Puedes comenzar a leer y escuchar una vez que agregues tu audiolibro de Audible a tu libro Kindle.</p>

            <hr/>
            <h4>Lee y escucha</h4>
            <p>Lee y escucha al mismo tiempo con la función de lectura de inmersión, que narra y destaca el texto mientras lees para que te sumerjas en la historia. La función de lectura de inmersión está disponible en las tablets y teléfonos Fire, y en la aplicación Kindle para Android.</p>
        </>);
    }

    return (<>
        <h3 className={styles.keepItGoing}>Keep the story going</h3>

        <div className={styles.narrationImage}>
            <img src="https://m.media-amazon.com/images/G/01/kindle/merch/global/narration-modal-devices._CB485979979_.png" />
        </div>

        <p><span className={styles.textHighlight}>Switch</span> between reading the Kindle book and listening to the Audible audiobook without losing your place with Whispersync for Voice.</p>
        <p><span className={styles.textHighlight}>Sync</span> your book across different devices and always pick up where you left off.</p>
        <p><span className={styles.textHighlight}>Save</span> on the Audible audiobook and get a reduced price with the Kindle book.</p>
        <p>
            <span className={styles.textHighlight}>Listen</span> on the Kindle App (iOS/Android) when you buy.
            <br/>
            You can also listen on Fire tablets, Kindle Touch, Kindle Keyboard, Bluetooth enabled Kindle eReaders and the Audible App (iOS/Android/Windows).
        </p>

        <hr/>
        <h4>What is an Audible audiobook?</h4>
        <p>An audiobook is an audio version of your book provided by Audible.</p>
        <p>With audiobooks you can keep a book going, even during your commute, doing chores, working out, whenever your eyes are occupied but your mind is free.</p>

        <hr/>
        <h4>What are the advantages of adding an Audible audiobook to my Kindle eBooks?</h4>
        <p>You can move between reading and listening seamlessly- your place is saved automatically, even when you switch devices. Listening can also bring the story to life, illuminate characters, and take you deeper into the books you love.</p>

        <hr/>
        <h4>How do I activate this on my device?</h4>
        <p>No activation is required, you can start reading and listening once you add Audible audiobook to your Kindle book.</p>

        <hr/>
        <h4>Read and Listen</h4>
        <p>Read and listen at the same time with Immersion Reading, which immerses you in a story by narrating and highlighting the text as you read. Immersion Reading is available on the Kindle Apps (iOS/Android) and Fire tablets.</p>
    </>);
};
