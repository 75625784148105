import React, { useContext } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { NativeAPI } from "src/utils/deviceUtils";

type LinkProps = { href: string; };
const Link: React.FC<LinkProps> = ({ href, children }) => {
    return (<span className={styles.activeText} onClick={() => NativeAPI.openWebPage(href)}>
        {children}
    </span>);
};

type PropTypes = {
    asin: string;
    displayablePromotion?: AapiDisplayablePromotion;
};

export const GreatOnKindleInfo: React.FC<PropTypes> = ({ asin, displayablePromotion }) => {
    const deviceContext = useContext(DeviceContext);
    const savingsAmount = displayablePromotion?.combinedSavings?.savingsAmount;
    const savingsString = savingsAmount?.amount?.toLocaleString(deviceContext.locale, { style: 'currency', currency: savingsAmount?.currency });

    // TODO: Figure out a clean way to get the translations for this UI. AST has translations with embedded markup (gross) but having over a dozen discrete entries is also gross.
    if (deviceContext.locale.startsWith("es")) {
        return (<>
            <h3>Sume créditos con esta compra de Kindle</h3>
            <div>
                <p>
                    Sume un <span className={styles.greatOnKindleInfoCredits}>crédito de {savingsString}</span> para gastar en su próximo
                    libro de <Link href="/-/es/b?node=16633723011"><span className={styles.programName}>Great on Kindle</span></Link> cuando
                    compre la edición de Kindle de este libro. Seleccione entre miles de libros populares, disponibles en categorías seleccionadas.
                </p>
            </div>

            <hr/>
            <h3>Cómo funcionan los créditos y cupones</h3>
            <div>
                <p>
                    Cuando los créditos estén disponibles, se aplicarán automáticamente la siguiente vez que compres un eBook elegible. Verás la
                    confirmación del uso del crédito en el resumen de tu pedido. Los créditos restantes se transferirán a tu siguiente compra. El
                    crédito no se aplica a la narración de Audible.
                </p>
                <p>
                    Si aplicas un cupón, se mostrarán los ahorros al comprar un eBook elegible. Verás la confirmación de los ahorros por
                    cupones en el resumen de tu pedido.
                </p>
            </div>

            <hr/>
            <h3>Términos y condiciones</h3>
            <div>
                <p>
                    La oferta aplica únicamente a los eBooks señalados con &quot;Great on Kindle&quot; disponibles a través de Amazon.com
                    (no incluye complementos de narración mediante la función Audible). Disponible para residentes de EE. UU. solo
                    en pedidos de 10 eBooks o menos. No se puede ganar crédito promocional en compras para regalo y &quot;para otras
                    personas&quot; realizadas con créditos promocionales. Las compras de eBooks están sujetas a
                    los <Link href="/-/es/gp/help/customer/display.html?nodeId=201014950">Términos de la Tienda Kindle</Link>. Los
                    créditos promocionales se pueden usar solo para la compra de eBooks &quot;Great on Kindle&quot; (no incluye complementos
                    de narración audible). El o los créditos promocionales se acreditarán en su cuenta dentro de un día desde la compra
                    inicial que califica y, una vez acreditados, se aplicarán automáticamente en compras futuras que califiquen. Recibirá
                    un correo electrónico de Amazon que confirma que los créditos promocionales se han agregado a su cuenta de cliente.
                    Los créditos promocionales vencen 30 días después de su recepción. La oferta no es transferible y no se puede revender.
                    Los créditos promocionales se aplicarán automáticamente en su próxima compra en Kindle de eBooks &quot;Great on Kindle&quot; y
                    se pueden ver en el resumen de sus pedidos después de realizar el pedido de un libro elegible. Si se devuelve algún
                    contenido relacionado con esta oferta, su reembolso será igual al monto que pagó por el contenido, sujeto a las
                    correspondientes políticas de reembolso. Si viola alguna de estas condiciones, la oferta no será válida. Amazon se
                    reserva el derecho de modificar o cancelar esta oferta en cualquier momento.
                </p>
            </div>
        </>);
    }

    return (<>
        <h3>Earn credits with this Kindle purchase</h3>
        <div>
            <p>
                Earn a <span className={styles.greatOnKindleInfoCredits}>{savingsString} credit</span> to spend on your
                next <Link href="/b?node=16633723011"><span className={styles.programName}>Great on Kindle</span></Link> book
                when you buy the Kindle edition of this book. Select from thousands of popular books, available in select categories.
            </p>
        </div>

        <hr/>
        <h3>How credits and coupons work</h3>
        <div>
            <p>
                When available, credits will be automatically applied the next time you buy a qualifying eBook. You’ll see confirmation of credit usage
                on your order summary. Any remaining credits will carry over to your next purchase. Credit does not apply to Audible narration.
            </p>
            <p>
                If applying a coupon, you’ll get the savings shown when buying a qualifying eBook. You’ll see confirmation of coupon savings on your order summary.
            </p>
        </div>

        <hr/>
        <h3>Terms and conditions</h3>
        <div>
            <p>
                Offer only applies to eBooks with “Great on Kindle” badging made available through Amazon.com (excluding Audible narration add-ons).
                Available to U.S. residents on orders of 10 or fewer eBook units only. Promotional credit cannot be earned on gifting and
                “buy for others” purchases made with promotional credits. Purchases of eBooks are subject to
                the <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201014950">Kindle Store Terms</Link>. Promotional
                credits may be used toward the purchase of “Great on Kindle” eBooks only (excluding Audible narration add-ons).
                The promotional credit(s) will be credited to your account within one day of initial qualifying purchase and, once credited, will
                be automatically applied to future qualifying purchases. You will receive an email from Amazon confirming that the promotional
                credits have been added to your customer account. Promotional credits expire 30 days after receipt. Offer is non-transferable
                and may not be resold. The promotional credits will automatically apply to your next eligible Kindle “Great on Kindle” eBook
                purchase and can be viewed in your order summary after you place an order for an eligible book. If any of the content related
                to this offer is returned, your refund will equal the amount you paid for the content, subject to applicable refund policies.
                If you violate any of these terms, the offer will be invalid. Amazon reserves the right to modify or cancel this offer at any time.
            </p>
        </div>
    </>);
};
