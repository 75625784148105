import { Theme } from "./themeUtils";
import cuLogoLight from "src/images/logos/cu_light.svg";
import cuLogoDark from "src/images/logos/cu_dark.svg";
import kuLogoLight from "src/images/logos/ku_light.svg";
import kuLogoDark from "src/images/logos/ku_dark.svg";
import kuLogoAudioLight from "src/images/logos/ku_light_audio.svg";
import kuLogoAudioDark from "src/images/logos/ku_dark_audio.svg";
import kuLogoLightFr from "src/images/logos/ku_light_fr.svg";
import kuLogoDarkFr from "src/images/logos/ku_dark_fr.svg";
import kuLogoAudioLightFr from "src/images/logos/ku_light_audio_fr.svg";
import kuLogoAudioDarkFr from "src/images/logos/ku_dark_audio_fr.svg";
import prLogoLight from "src/images/logos/pr_light.svg";
import prLogoDark from "src/images/logos/pr_dark.svg";
import prBadge from "src/images/logos/pr_badge.svg";
import prBadgeWithAudio from "src/images/logos/pr_badge_audio.svg";
import rplLogoLight from "src/images/logos/rpl_co_brand_light.svg";
import rplLogoDark from "src/images/logos/rpl_co_brand_dark.svg";
import afrLogoLight from "src/images/logos/afr_light.svg";
import afrLogoDark from "src/images/logos/afr_dark.svg";
import debug from "./debugUtils";

export const programCodeFromSticker = (stickerCode?: string) => {
    if (debug.get("forceRpl")) {
        return "READ_PLUS_LISTEN";
    }
    if (stickerCode?.includes("PJku")) {
        return "KINDLE_UNLIMITED";
    }
    if (stickerCode?.includes("PJcu")) {
        return "COMICS_UNLIMITED";
    }
    if (stickerCode?.includes("PJpr")) {
        return "PRIME";
    }
    if (stickerCode?.includes("PJrpl")) {
        return "READ_PLUS_LISTEN";
    }
    return "";
};

type ProgramImage = {
    src: string;
    altKey: string;
    extraStyleName?: string;
};

export const getProgramLogo = (programCode: string, theme: Theme, domain: string) : ProgramImage | undefined => {
    if (debug.get("forceRpl")) {
        programCode = "READ_PLUS_LISTEN";
    }
    const isDark = theme === Theme.DARK;
    switch (programCode) {
        case "PRIME":
            return {
                src: isDark ? prLogoDark : prLogoLight,
                altKey: "prime-reading"
            };
        case "KINDLE_UNLIMITED":
            return {
                src: domain === "amazon.fr"
                    ? (isDark ? kuLogoDarkFr : kuLogoLightFr)
                    : (isDark ? kuLogoDark : kuLogoLight),
                altKey: "kindle-unlimited"
            };
        case "COMICS_UNLIMITED":
            return {
                src: isDark ? cuLogoDark : cuLogoLight,
                altKey: "comixology-unlimited"
            };
        case "READ_PLUS_LISTEN":
            return {
                src: isDark ? rplLogoDark : rplLogoLight,
                altKey: "read-plus-listen-logo-alt-text",
                extraStyleName: "rplLogo",
            };
        case "FIRST_READS":
            return {
                src: isDark ? afrLogoDark : afrLogoLight,
                altKey: "amazon-first-reads-logo-alt-text",
            };
    }
};

export const hasAudio = (kindleProgram?: KindleProgram) => {
    // TODO: KindleProgramLow cost doesn't include the audible companion flag like PA/PB did.
    //       The badge configs do include the `hasAudibleCompanion` flag but it isn't passed through.
    //       Add some work around logic here for now until we can get that added on the back end.
    //       KU with headphones sticker style code includes the word "headphones", so check that,
    //       otherwise check for any of the known sticker physicalIds that `hasAudibleCompanion` true;
    //
    // https://code.amazon.com/packages/KindleProgramAapiG2S2Config/blobs/mainline/--/configuration/pipeline-g2s2/data/app_configs/badge_information/prime_reading_headphones.ion
    // https://code.amazon.com/packages/KindleProgramAapiG2S2Config/blobs/mainline/--/configuration/pipeline-g2s2/data/app_configs/badge_information/kindle_unlimited_headphones.ion
    // https://code.amazon.com/packages/KindleProgramAapiG2S2Config/blobs/mainline/--/configuration/pipeline-g2s2/data/app_configs/badge_information/kindle_unlimited_readpluslisten.ion
    const withHeadphonesPhysicalIds = ["21zgkj28jrL", "21cx0fW78GL", "21x1kn7rsXL", "21q7RgWPn4L", "21HAZ1w+5DL"];
    return kindleProgram?.isHeadphones === true || kindleProgram?.stickerStyleCode?.includes("headphones") || withHeadphonesPhysicalIds.includes(kindleProgram?.physicalId ?? "");
};

export const getProgramSticker = (theme: Theme, domain: string, kindleProgram?: KindleProgram, isAFR?: boolean) : ProgramImage | undefined => {
    const isDark = theme === Theme.DARK;
    if (isAFR) {
        return {
            src: isDark ? afrLogoDark : afrLogoLight,
            altKey: "amazon-first-reads-logo-alt-text",
        };
    }
    if (!kindleProgram) {
        return;
    }
    const withAudio = hasAudio(kindleProgram);

    const programCode = programCodeFromSticker(kindleProgram?.stickerStyleCode);
    switch (programCode) {
        case "PRIME":
            return {
                src: withAudio ? prBadgeWithAudio : prBadge,
                altKey: "prime-reading"
            };
        case "KINDLE_UNLIMITED": {
            let src;
            if (domain === "amazon.fr") {
                if (withAudio) {
                    src = isDark ? kuLogoAudioDarkFr : kuLogoAudioLightFr;
                } else {
                    src = isDark ? kuLogoDarkFr : kuLogoLightFr;
                }
            } else if (withAudio) {
                src = isDark ? kuLogoAudioDark : kuLogoAudioLight;
            } else {
                src = isDark ? kuLogoDark : kuLogoLight;
            }
            return {
                src,
                altKey: "kindle-unlimited"
            };
        }
        case "COMICS_UNLIMITED":
            return {
                src: isDark ? cuLogoDark : cuLogoLight,
                altKey: "comixology-unlimited"
            };
        case "READ_PLUS_LISTEN":
            return {
                src: isDark ? rplLogoDark : rplLogoLight,
                altKey: "read-plus-listen-logo-alt-text"
            };
    }
};
