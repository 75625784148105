import React, { useContext, useEffect, useState } from "react";
import { NativeAPI } from "src/utils/deviceUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import styles from "./BuyBox.module.scss";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { getProgramLogo } from "src/utils/programLogoUtils";
import DeviceContext from "src/contexts/DeviceContext";
import debug from "src/utils/debugUtils";

type PropTypes = {
    asin: string;
    buyingOption?: AapiBuyingOption;
    subscribeToEmailCallback?: (subscribe: boolean) => void;
};

const checkForEmailUpsell = (buyingOption?: AapiBuyingOption): boolean => {
    return !!buyingOption?.callToAction?.entity?.oneClick?.data?.parameters?.find(param => param.name === "items[0].optional.botmSignupForEmail")
        || debug.get("forceAfrEmailUpsell");
}

const checkIfPrime = (buyingOption?: AapiBuyingOption): boolean => {
    return buyingOption?.callToAction?.entity?.oneClick?.data?.parameters?.find(param => param.name === "items[0].action.botmType")?.value === "PRIME";
}

const FirstReadsUpsell: React.FC<PropTypes> = ({ asin, buyingOption, subscribeToEmailCallback }: PropTypes) => {
    const hasEmailUpsell = checkForEmailUpsell(buyingOption);
    const translations = useContext(TranslationsContext);
    const deviceContext = useContext(DeviceContext);
    const [subscribeToEmail, setSubscribeToEmail] = useState(true);
    const metrics = newMetricsWithContext("BuyBox.AfrUpsell", asin);

    useEffect(() => {
        subscribeToEmailCallback?.(subscribeToEmail);
    }, [subscribeToEmail, subscribeToEmailCallback]);

    const afrUpsellText = buyingOption?.kindleProgram?.entity?.shortMessage;
    const afrLearnMoreText = buyingOption?.kindleProgram?.entity?.learnMore?.data?.displayString ?? translations.getText("learn-more");

    const openAfrLearnMore = () => {
        metrics.recordBehavioralMetric(`AfrUpsellLearnMore.click`, 1);
        NativeAPI.openWebPage(buyingOption?.kindleProgram?.entity?.learnMore?.url ?? "/firstreads");
    };

    const afrLogo = getProgramLogo("FIRST_READS", deviceContext.theme, deviceContext.domain);

    const isPrime = checkIfPrime(buyingOption);

    return (<div className={styles.afrUpsell}>
        <div className={styles.afrLogoContainer}>
            <img className={styles.afrLogo} src={afrLogo?.src} alt={translations.getText(afrLogo?.altKey ?? "")} />
        </div>
        <div className={styles.afrUpsellTextContainer}>
            <div className={styles.afrUpsellText}>
                {afrUpsellText}
                &nbsp;
                <span className={`${styles.learnMore} ${styles.activeText}`} role="button" tabIndex={0} onClick={openAfrLearnMore}>{afrLearnMoreText}</span>
            </div>
        </div>
        {hasEmailUpsell && (<div className={styles.afrEmailUpsell}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={subscribeToEmail}
                onChange={() => setSubscribeToEmail(!subscribeToEmail)}
            />
            <label className={styles.checkboxLabel}>
                {isPrime
                    ? translations.getText("pf-afr-prime-email-subscribe-message")
                    : translations.getText("pf-afr-nonprime-email-subscribe-message")
                }
            </label>
        </div>)}
    </div>);
};

export default FirstReadsUpsell;
