import React, { useCallback, useContext, useState, useEffect, useRef, memo } from "react";
import styles from "./MoreLikeThis.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import Spinner from "../Spinner/Spinner";
import { RecsItem, RecsResponse, getRecs } from "src/utils/asinMetadataUtils";
import debug from "src/utils/debugUtils";
import BookCoverWithPlaceholder from "../BookCoverWithPlaceholder/BookCoverWithPlaceholder";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";


type BookImagePropTypes = {
    item: RecsItem;
    index: number;
    onTapCallback: (index: number) => void;
};

const BookImage: React.FC<BookImagePropTypes> = ({ item, index, onTapCallback }: BookImagePropTypes) => {
    const imageData = item.images?.lowRes || item.images?.hiRes;
    return (
        <li className={styles.bookImage} onClick={() => onTapCallback(index)}>
            <BookCoverWithPlaceholder
                asin={item.asin}
                title={item.title ?? ""}
                imagePxSize={200}
                imagePxType="height"
                physicalId={imageData?.physicalId}
            />
        </li>
    );
};

interface PropTypes {
    asin: QuickViewAsinMetadata;
    disableScrolling?: boolean;
}

// TODO: consider pulling the state up into a more stable location (e.g., a RecsManagerContext or something)
// TODO: consider saving scroll offset position so returning to the shoveler after clicking in returns to the original spot (or last active item)
// TODO: pagination integration
// TODO: Animations
// TODO: consider breaking this out into a more generic "ItemShovelerWithOptionalFilters" component or something like that to cover the Vella use cases
const MoreLikeThis: React.FC<PropTypes> = ({ asin, disableScrolling }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const [recsFetched, setRecsFetched] = useState(false);
    const [recsFetchInProgress, setRecsFetchInProgress] = useState(false);
    const [reFetchInProgress, setReFetchInProgress] = useState(false);
    const [recs, setRecs] = useState<RecsResponse>();
    const selectedTagsRef = useRef<Set<string>>(new Set<string>());
    const enableLoadFailureMessages = debug.get('enableLoadFailureMessages');

    const fetchRecs = useCallback(() => {
        if (recsFetchInProgress || recsFetched || recs?.unrecoverableError) {
            return;
        }
        setRecsFetchInProgress(true);
        const tags = Array.from(selectedTagsRef.current);
        getRecs(asin.asin, tags).then(data => {
            debug.log(data);
            setRecs(data);
            if (!data.loadFailed && data.recs) {
                setRecsFetched(true);
            }
        }).finally(() => setRecsFetchInProgress(false));
    }, [asin.asin, recs?.unrecoverableError, recsFetchInProgress, recsFetched]);

    const refreshRecs = () => {
        const tags = Array.from(selectedTagsRef.current);
        setReFetchInProgress(true);
        getRecs(asin.asin, tags).then(data => {
            debug.log(data);
            if (!data.loadFailed && data.recs) {
                setRecs(data);
            }
        }).finally(() => setReFetchInProgress(false));
    };

    const recsAsQvMetadata = (): QuickViewAsinMetadata[] | undefined => recs?.recs?.map((item: RecsItem) => {
            const data: QuickViewAsinMetadata = {
                asin: item.asin,
                title: item.title,
                physicalId: item.images?.lowRes?.physicalId || item.images?.hiRes?.physicalId
            };
            return data;
        });

    const openQvAtIndex = (index: number) => {
        workflowContext.openQv(recsAsQvMetadata() ?? [], index);
    };

    useEffect(() => {
        if (!recsFetched) { fetchRecs(); }
    }, [recsFetched, fetchRecs]);

    const getBookCoversComponent = () => {
        if (recsFetched) {
            return (
                <ul className={`${styles.horizontalScroller} ${styles[deviceContext.theme]} ${disableScrolling ? styles.disableScrolling : ""}`}>
                    {recs?.recs?.map((item, index) =>
                        <BookImage
                            key={item.asin + index} item={item} index={index} onTapCallback={openQvAtIndex}
                        />
                    )}
                </ul>
            );
        }
        return (
            <div className={styles.spinner}>
                <Spinner />
                {recs?.loadFailed && enableLoadFailureMessages && <div className={styles.loadFailure}>{recs.error}</div>}
            </div>
        );
    }

    const isTagSelected = (tag?: string) => tag && selectedTagsRef.current.has(tag);

    const toggleTag = (tag?: string) => {
        if (tag) {
            debug.log(`toggleTag: ${tag}`);
            if (isTagSelected(tag)) {
                selectedTagsRef.current.delete(tag);
            } else {
                selectedTagsRef.current.clear();
                selectedTagsRef.current.add(tag);
            }
            refreshRecs();
        }
    };

    const getTagsComponent = () => {
        if (recsFetched) {
            return (
                <ul className={`${styles.horizontalScroller} ${styles.tagsCarousel} ${styles[deviceContext.theme]} ${disableScrolling ? styles.disableScrolling : ""}`}>
                    {recs?.tags?.map((item, index) =>
                        <li
                            key={item.id ?? "" + index}
                            className={[styles.tagsPill, isTagSelected(item.id) ? styles.selected : ""].join(" ")}
                            onClick={() => toggleTag(item.id)}
                        >
                            {item.name}
                        </li>
                    )}
                </ul>
            );
        }
        return null;
    };

    if (recs?.unrecoverableError && !enableLoadFailureMessages) {
        return null;
    }

    return (
        <section className={[reFetchInProgress ? styles.refetchInProgress : "", styles[deviceContext.theme]].join(" ")}>
            <div className={styles.label}>
                {translations.getText("more-like-this")}
            </div>
            {getTagsComponent()}
            {getBookCoversComponent()}
        </section>
    );
};

export default memo(MoreLikeThis);
