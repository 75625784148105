import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./AudioSampleButton.module.scss";
import Button from "../Button/Button";
import AudioPlayerContext from "src/contexts/AudioPlayerContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import DeviceContext from "src/contexts/DeviceContext";
import { Theme } from "src/utils/themeUtils";
import { recordBehavioralMetric } from "src/utils/metricsUtils";

type PropTypes = {
    item: QuickViewAsinMetadata;
};

// Inline play and pause SVGs so we can set colors using `currentColor` in `fill`
const PlayIcon = () => (
    <svg width="16" height="20" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.6151 4.49682L1.21171 0.615439C1.09064 0.542121 0.952248 0.502313 0.810723 0.500098C0.669199 0.497882 0.52963 0.533338 0.406322 0.60283C0.283015 0.672323 0.180402 0.773354 0.109002 0.895567C0.0376025 1.01778 -1.68271e-05 1.15678 7.1241e-08 1.29832V9.06109C-5.97451e-05 9.20269 0.0375493 9.34176 0.10897 9.46404C0.18039 9.58631 0.28305 9.68738 0.40642 9.75688C0.52979 9.82639 0.669429 9.86182 0.811013 9.85956C0.952597 9.85729 1.09103 9.8174 1.21211 9.74398L7.6151 5.86259C7.73257 5.79149 7.82972 5.69128 7.89715 5.57165C7.96458 5.45203 8 5.31703 8 5.17971C8 5.04239 7.96458 4.90739 7.89715 4.78776C7.82972 4.66814 7.73257 4.56793 7.6151 4.49682Z" fill="currentColor"/>
    </svg>
);

const PauseIcon = () => (
    <svg width="16" height="24" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 0.5H0.666667C0.298477 0.5 0 0.798477 0 1.16667V10.5C0 10.8682 0.298477 11.1667 0.666667 11.1667H2C2.36819 11.1667 2.66667 10.8682 2.66667 10.5V1.16667C2.66667 0.798477 2.36819 0.5 2 0.5Z" fill="currentColor"/>
    <path d="M7.33301 0.5H5.99967C5.63148 0.5 5.33301 0.798477 5.33301 1.16667V10.5C5.33301 10.8682 5.63148 11.1667 5.99967 11.1667H7.33301C7.7012 11.1667 7.99967 10.8682 7.99967 10.5V1.16667C7.99967 0.798477 7.7012 0.5 7.33301 0.5Z" fill="currentColor"/>
    </svg>
);

const leftPadTimeUnits = (count: number) => {
    return count >= 10 ? count : `0${count}`;
};

const formatTimestamp = (rawSeconds: number): string => {
    const seconds = Math.floor(rawSeconds % 60);
    const minutes = Math.floor(rawSeconds / 60);
    return `${minutes}:${leftPadTimeUnits(seconds)}`;
};

const AudioSampleButton: React.FC<PropTypes> = ({ item }) => {
    const deviceContext = useContext(DeviceContext);
    const src = item.audibleUrl ?? "";
    const audioPlayerContext = useContext(AudioPlayerContext);
    const [isPlaying, setIsPlaying] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const translations = useContext(TranslationsContext);
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const isLightTheme = deviceContext.theme === Theme.LIGHT;
    const displayText = item.isTandem && item.audibleDisplayText || translations.getText("audible-sample");
    let hasPlayed = false;

    const getArtworkUrl = () => {
        return `https://m.media-amazon.com/images/${item.physicalId ? "I/" + item.physicalId : "P/" + item.asin}.jpg`;
    };

    const setMediaSessionMetadata = () => {
        if ("mediaSession" in navigator) {
            const mediaMetadata = new MediaMetadata({
                title: item.title,
                artist: item.authors,
                artwork: [{ src: getArtworkUrl() }],
            });
            navigator.mediaSession.metadata = mediaMetadata;
        }
    };

    const handleAudioClick = () => {
        if (!hasPlayed) {
            hasPlayed = true;
            recordBehavioralMetric({namespace: "AudioSampleButton", qv_asin: item.asin}, "AudibleSample.click", 1);
        }
        const player = audioPlayer.current;
        if (player) {
            if (player.paused || player.ended) {
                player.play();
                setMediaSessionMetadata();
            } else {
                player.pause();
            }
        }
    };

    const handlePlay = () => {
        audioPlayerContext.setSrc(src);
        setIsPlaying(true);
    };

    const handlePause = () => setIsPlaying(false);

    const handlePlayEnded = () => {
        setTimestamp(0);
        setIsPlaying(false)
    };

    const handleTimeUpdate = () => setTimestamp(audioPlayer.current?.currentTime ?? 0);

    const getPlaybackPercentage = (): number => {
        const player = audioPlayer.current;
        if (!player) {
            return 0;
        }

        return (player.currentTime / player.duration) * 100;
    };

    useEffect(() => {
        if (audioPlayerContext.src !== src) {
            audioPlayer.current?.pause();
        }
    }, [audioPlayerContext.src, src]);

    useEffect(() => {
        const audioPlayerCurrent = audioPlayer.current;

        if (!audioPlayerCurrent) {
            return;
        }

        // Handle audio being played/paused via media controls (not clicking our button)
        audioPlayerCurrent.addEventListener("play", handlePlay);
        audioPlayerCurrent.addEventListener("pause", handlePause);

        // Handle the end of playback being reached
        audioPlayerCurrent.addEventListener("ended", handlePlayEnded);

        // Handle playback progress updating
        audioPlayerCurrent.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            audioPlayerCurrent.removeEventListener("play", handlePlay);
            audioPlayerCurrent.removeEventListener("pause", handlePause);
            audioPlayerCurrent.removeEventListener("ended", handlePlayEnded);
            audioPlayerCurrent.removeEventListener("timeupdate", handleTimeUpdate);
            audioPlayerCurrent.pause();
        };
    }, []);

    const isInProgress = isPlaying || timestamp > 0;
    const elapsedString = formatTimestamp(timestamp);
    const label = isInProgress ? elapsedString : displayText;
    const ariaLabel = !isInProgress
        ? translations.getText("audible-sample")
        : (isPlaying
            ? translations.formatText("audible-sample-playing", {elapsed: elapsedString})
            : translations.formatText("audible-sample-paused", {elapsed: elapsedString})
        );
    const backgroundColor = isLightTheme ? "#EAEAEA" : "#2C2C2E";
    const labelColor = isLightTheme ? "#000000" : "#FFFFFF";

    return (
        <Button
            onClick={handleAudioClick}
            showProgressBar={isInProgress}
            progressPercentage={getPlaybackPercentage()}
            backgroundColor={backgroundColor}
            ariaLabel={ariaLabel}
        >
            <span className={styles.playPauseIcon} style={{ color: labelColor }}>
                {isPlaying ? <PauseIcon/> : <PlayIcon/>}
            </span>
            <span style={{ color: labelColor }}>{label}</span>
            <audio src={src} ref={audioPlayer} preload="none" />
        </Button>
    );
};

export default AudioSampleButton;
