import React, { useCallback, useContext, useRef } from "react";
import styles from "./PurchaseFlowButton.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { recordBehavioralMetric } from "src/utils/metricsUtils";
import focusHelper from "src/utils/focusHelper";
import { InlinedBuyBox } from "../BuyBox/BuyBox";
import DeviceContext from "src/contexts/DeviceContext";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
    inlineBuyBox?: boolean;
};

const PurchaseFlowButton: React.FC<PropTypes> = ({ metadata, inlineBuyBox }: PropTypes) => {
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const deviceContext = useContext(DeviceContext);
    const a11yTarget = useRef<HTMLButtonElement>(null);

    const requestA11yFocus = useCallback(() => {
        focusHelper.requestFocus(a11yTarget.current);
    }, [a11yTarget]);

    const openPurchaseFlow = () => {
        recordBehavioralMetric({namespace: "PurchaseFlowButton", qv_asin: metadata.asin}, "PurchaseFlowButton.click", 1);
        workflowContext.showBuyBox(metadata, requestA11yFocus)
    }

    const getCta = () => {
        if (metadata.canPreOrder === true && !(metadata.hasFreeAfrOption === true)) {
            return translations.getText("preorder-this-book");
        }
        return deviceContext.buyBox.useGetThisBook
            ? translations.getText("get-this-book")
            : translations.getText("buy-this-book");
    };

    if (inlineBuyBox) {
        return (<InlinedBuyBox data={metadata} />);
    }

    return (
        <button type="button" className={styles.purchaseFlowButton} onClick={openPurchaseFlow} ref={a11yTarget}>
            <div className={styles.label}>
                {getCta()}
                <div className={styles.chevronIconContainer}>
                    <div className={styles.chevronIcon} />
                </div>
            </div>
        </button>
    );
};

export default PurchaseFlowButton;
