import React, { useContext } from "react";
import styles from "./RichContent.module.scss";
import DeviceContext from "src/contexts/DeviceContext";

export type RichContentExtensions = {
    linkHandler: (url?: string) => void;
    linkClassName: string;
    moneyClassName: string;
};

interface RichContentProps {
    content: RichContent;
    extensions?: RichContentExtensions;
}

const handleSemanticContent = (content: RichContent, extensions?: RichContentExtensions) => {
    const semanticContent = content.semanticContent;
    if (!semanticContent) {
        return null;
    }
    const isB = !!semanticContent.strong;
    const isI = !!semanticContent.emphasized;
    const isBI = isB && isI;
    const childText = semanticContent.content.text;
    if (childText) {
        if (isBI) { return (<b><i dangerouslySetInnerHTML={{ __html: childText }}></i></b>); }
        if (isB) { return (<b dangerouslySetInnerHTML={{ __html: childText }}></b>); }
        if (isI) { return (<i dangerouslySetInnerHTML={{ __html: childText }}></i>); }
        return (<RichContent content={semanticContent.content} extensions={extensions} />);
    }
    if (isBI) { return (<b><i><RichContent content={semanticContent.content} extensions={extensions} /></i></b>); }
    if (isB) { return (<b><RichContent content={semanticContent.content} extensions={extensions} /></b>); }
    if (isI) { return (<i><RichContent content={semanticContent.content} extensions={extensions} /></i>); }
    return (<RichContent content={semanticContent.content} extensions={extensions} />);
};

const handleHeading = (content: RichContent, extensions?: RichContentExtensions) => {
    const heading = content.heading || content.headingContent;
    if (!heading) {
        return null;
    }
    const childText = heading.content.text;
    let level = heading.level;
    if (level < 1) { level = 1; }
    if (level > 6) { level = 6; }
    const tag = `h${level}`;
    if (childText) {
        return React.createElement(tag, {
            dangerouslySetInnerHTML: { __html: heading.content.text}
        });
    }
    return React.createElement(tag, {}, (<RichContent content={heading.content} extensions={extensions} />));
};

const handleMoney = (content: RichContent, locale: string, extensions?: RichContentExtensions) => {
    const money = content.money;
    if (!(money && money?.amount !== undefined && money?.currency)) {
        return null;
    }
    return (<span className={extensions?.moneyClassName}>{money?.amount?.toLocaleString(locale, { style: 'currency', currency: money?.currency })}</span>);
};

const handleMedia = (content: RichContent) => {
    const media = content?.media?.content;
    if (!(media && media.extension && media.physicalId)) {
        return null;
    }
    return (<img src={`https://m.media-amazon.com/images/I/${media.physicalId}.${media.extension}`} alt={content.media?.supportText} />);
};

const handleLink = (content: RichContent, extensions?: RichContentExtensions) => {
    const link = content.link;
    if (!(link && link.url && link.content)) {
        return null;
    }
    if (!extensions?.linkHandler || !extensions.linkClassName) {
        return (<RichContent content={link.content} extensions={extensions} />);
    }
    return (<span className={extensions.linkClassName} onClick={() => {
        extensions?.linkHandler?.(link.url);
    }}>
        <RichContent content={link.content} extensions={extensions} />
    </span>);
};

const RichContent: React.FC<RichContentProps> = ({ content, extensions }) => {
    const deviceContext = useContext(DeviceContext);
    return (
        <React.Fragment>
            {content.text && (<span dangerouslySetInnerHTML={{ __html: content.text }} className={styles.text} />)}
            {content.semanticContent?.content && handleSemanticContent(content, extensions)}
            {content.paragraph && (
                <span className={`${styles.paragraph}`}>
                    <RichContent content={content.paragraph} extensions={extensions} />
                </span>
            )}
            {(content.list && content.list.type === "UNORDERED") && (
                <ul className={styles.ul}>
                    { content.list.items.map((nestedContent, index) => (
                        <li key={index} >
                            <RichContent content={nestedContent} extensions={extensions} />
                        </li>
                    ))}
                </ul>
            )}
            {(content.list && content.list.type !== "UNORDERED") && (
                <ol className={styles.ol}>
                    { content.list.items.map((nestedContent, index) => (
                        <li key={index}>
                            <RichContent content={nestedContent} extensions={extensions} />
                        </li>
                    ))}
                </ol>
            )}
            {content.fragments?.map((nestedContent, index) => (
                <RichContent key={index} content={nestedContent} extensions={extensions} />
            ))}
            {(content.heading || content.headingContent) && handleHeading(content)}
            {content.money && handleMoney(content, deviceContext.locale, extensions)}
            {content.media && handleMedia(content)}
            {content.link && handleLink(content, extensions)}
        </React.Fragment>
    );
};

export default RichContent;
