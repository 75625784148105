import React from "react";
import styles from "./BuyBox.module.scss";
import RichContent from "../RichContent/RichContent";

type PropTypes = {
    asin: string;
    displayablePromotion: AapiDisplayablePromotion;
    onClick: () => void;
};

const GreatOnKindle: React.FC<PropTypes> = ({ asin, displayablePromotion, onClick }) => {
    const labelContent = displayablePromotion?.longMessage?.label;
    const messageContent = displayablePromotion?.longMessage?.message;
    if (!(labelContent && messageContent)) {
        return null;
    }
    return (<div className={[styles.greatOnKindle].join(" ")} onClick={onClick}>
        <span className={[styles.addonBadgeLabel, styles.addonBadge ].join(" ")}>
            <RichContent content={labelContent} />
        </span>
        <span className={[styles.addonBadgeMessage, styles.activeText].join(" ")}>
            <RichContent content={messageContent} />
        </span>
      </div>);
};

export default GreatOnKindle;
