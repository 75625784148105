import { CONTENT_TYPE } from "src/utils/asinUtils";
import { getCookieValue, KINDLE_STORE_COOKIE_NAME } from "src/utils/cookieUtils";
import { getCurrentBaseUrl, isURLAbsolute, setQueryParams } from "src/utils/urlUtils";
import { HttpRequestArgs } from "./ajaxUtils";
import debug from "./debugUtils";

const getAndroidAPI = () => window.WebViewWidget;
const getAndroidQuickViewAPI = () => window.QuickView;
const getiOSAPI = () => window.webkit?.messageHandlers;

window.AndroidCallbacks = window.AndroidCallbacks || {};

const getNextRequestId = (() => {
    let id = 0;
    return () => id++;
})();

const getConnectionStateAsString = (state?: number) => {
    if (state === 0) { return "None"; }
    if (state === 1) { return "WAN"; }
    if (state === 2) { return "WiFi"; }
    return "Unknown";
}

export type KindleStoreParametersI = {
    w?: string;
    h?: string;
    dpi?: string;
    deviceType?: string;
    osv?: string;
    appv?: string;
    browserMode?: string;
    storeType?: string;
    eid?: string;
    locale?: string;
    theme?: string;
    ref?: string;
};

export interface DeviceDetailsI {
    surfaceType: SURFACE_TYPE;
    isIOS: boolean;
    isTablet: boolean;
    isFOS: boolean;
    isApp: boolean;
    isDesktop: boolean;
}

export function getDeviceDetails(): DeviceDetailsI {
    const surfaceType = getSurfaceType();
    return {
        surfaceType,
        isIOS: isIOSDevice(surfaceType),
        isTablet: isDeviceTablet(surfaceType),
        isFOS: isFOSDevice(surfaceType),
        isApp: isApp(),
        isDesktop: surfaceType === SURFACE_TYPE.desktop,
    };
}

export function isApp(): boolean {
    // TODO: get from kstore instead?
    return true;
    // return window.ReadingInsights.deviceAttributes.includes("app");
}

export enum SURFACE_TYPE {
    desktop = "desktop",
    iOSMobile = "ios_mob",
    iOSTablet = "ios_tab",
    androidMobile = "and_mob",
    androidTablet = "and_tab",
    fireTablet = "and_fos",
}

export enum BookState {
    /** Book is in an unknown or error state */
    AZPluginBookStateInLibraryUnknown = 0,
    /** Book has been marked for deletion and will be removed from the library */
    AZPluginBookStateInLibraryPendingDeletion = 1,
    /** Book has been deleted from device and removed from Cloud. For sideloaded or non-owned pdocs */
    AZPluginBookStateInLibraryDeleted = 2,
    /** Book has been deleted from device and moved to Cloud. For owned books and pdocs */
    AZPluginBookStateInLibraryInCloud = 3,
    /** Book has been downloaded or sideloaded to device */
    AZPluginBookStateInLibraryOnDevice = 4,
    /** Book is being downloaded */
    AZPluginBookStateInLibraryDownloading = 5,
    /** An attempt to download the book was made and the download failed. */
    AZPluginBookStateInLibraryDownloadFailed = 6,
    /** Book download progress is blocked */
    AZPluginBookStateInLibraryDownloadBlocked = 7,
}

export function getSurfaceType(): SURFACE_TYPE {
    const ua = navigator.userAgent;
    const iPad13 = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1; // workaround to detect iOS 13 on iPad
    if (ua.includes("iPad") || iPad13) {
        return SURFACE_TYPE.iOSTablet;
    } else if (ua.includes("iPhone") || ua.includes("iPod")) {
        return SURFACE_TYPE.iOSMobile;
    } else if (ua.includes("TOS Store") || ua.includes("Silk")) {
        // user agent generated from StoreUtils.kt in KAR.  Only 1P devices include "TOS Store."
        return SURFACE_TYPE.fireTablet;
    } else if (ua.includes("Android")) {
        // move Android 3P check after FOS check as both contain "Android"
        if (ua.includes("Mobile")) {
            return SURFACE_TYPE.androidMobile;
        } else {
            return SURFACE_TYPE.androidTablet;
        }
    } else {
        return SURFACE_TYPE.desktop;
    }
}

export function isDeviceTablet(surfaceType = getSurfaceType()): boolean {
    return (
        surfaceType === SURFACE_TYPE.androidTablet ||
        surfaceType === SURFACE_TYPE.fireTablet ||
        surfaceType === SURFACE_TYPE.iOSTablet
    );
}

export function isIOSDevice(surfaceType = getSurfaceType()): boolean {
    return [SURFACE_TYPE.iOSMobile, SURFACE_TYPE.iOSTablet].includes(surfaceType);
}

export function isFOSDevice(surfaceType = getSurfaceType()): boolean {
    return surfaceType === SURFACE_TYPE.fireTablet;
}

export function isSharingSupported(): boolean {
    return typeof navigator.share === "function" || typeof getAndroidQuickViewAPI()?.share === "function";
}

const getAbsoluteUrl = (url: string) => {
    return isURLAbsolute(url) ? new URL(url) : new URL(url, getCurrentBaseUrl());
}

/**
 * Currently we only have three use case to pass OriginType: KU, PRR, CU
 * For both iOS and Android
 * OriginType reference: https://tiny.amazon.com/149ny2s9i
 * ActionProgram(programCode, program, channel) which returned from offers
        KINDLE_UNLIMITED("KINDLE_UNLIMITED", "KU","ALL_YOU_CAN_READ"),
        PRIME_READING("PRIME", "PRR", "PRIME_READING"),
        COMICS_UNLIMITED("COMICS_UNLIMITED", "CU", "ALL_YOU_CAN_READ"),
 */
const programCodeToOriginType:{ [key: string]: string } = {
    "KINDLE_UNLIMITED": "KindleUnlimited",
    "PRIME": "Prime",
    "COMICS_UNLIMITED": "ComicsUnlimited",
}

export const NativeAPI = {
    popToRoot: () => {
        getiOSAPI()?.popToRoot?.postMessage({});
    },
    openWebPage: (url: string, viewTitle?: string, refTag?: string, useBib = false): void => {
        const absoluteUrl = getAbsoluteUrl(url);
        absoluteUrl.searchParams.append('bypass_qv', 'true');
        url = absoluteUrl.href;
        if (getAndroidAPI()) {
            if (getAndroidAPI()?.openWebUrlAsync) {
                getAndroidAPI()?.openWebUrlAsync(url, viewTitle || "", refTag || "", useBib);
            } else if (getAndroidAPI()?.openWebUrl) {
                getAndroidAPI()?.openWebUrl(url, viewTitle || "", refTag || "", useBib);
            } else {
                getAndroidAPI()?.openWebPage(url, viewTitle || "", refTag || "");
            }
        } else if (getiOSAPI()) {
            getiOSAPI()?.openWebPage.postMessage({ url, viewTitle, reftag: refTag });
        } else {
            window.open(url, "_blank");
        }
    },
    openBook: (
        asin: string,
        title?: string,
        authors?: string,
        type: CONTENT_TYPE = CONTENT_TYPE.EBOK,
        coverImagePosition: QuickViewCoverImagePosition | undefined = undefined,
        programCode = "",
    ): void | Promise<void> => {
        if (getAndroidQuickViewAPI()?.openBookWithPayloadAsync) {
            return new Promise((resolve, reject) => {   
                const requestId = getNextRequestId();
                window.AndroidCallbacks[requestId] = { resolve, reject };
                getAndroidQuickViewAPI()?.openBookWithPayloadAsync?.(
                    JSON.stringify({
                        asin,
                        type,
                        originType: programCodeToOriginType[programCode],
                        authors: authors?.split(", ")
                    }),
                    `AndroidCallbacks[${requestId}].resolve`,
                    `AndroidCallbacks[${requestId}].reject`
                )
            }).catch(e => {
                debug.error(`Failed to open book with payload Async in Android: ${e}`);
                if (getAndroidQuickViewAPI()?.openBookAsync) {
                    return getAndroidQuickViewAPI()?.openBookAsync?.(asin, title, authors, type);
                }
            }) as Promise<void>
        } else if (getAndroidQuickViewAPI()?.openBookAsync) {
            return getAndroidQuickViewAPI()?.openBookAsync?.(asin, title, authors, type);
        } else if (getAndroidAPI()?.openBookAsync) {
            return getAndroidAPI()?.openBookAsync?.(asin, type);
        } else if (getiOSAPI()) {
            return getiOSAPI()?.openBook?.postMessage({
                asin,
                type,
                coverImagePosition,
                originType: programCodeToOriginType[programCode],
            });
        }
    },
    openConnectivityAlert: (): void => {
        if (getAndroidQuickViewAPI()?.openConnectivityAlertAsync) {
            getAndroidQuickViewAPI()?.openConnectivityAlertAsync?.();
        } else if (getAndroidQuickViewAPI()?.openConnectivityAlert) {
            getAndroidQuickViewAPI()?.openConnectivityAlert?.();
        }
    },
    getConnectionStatus: (): Promise<ConnectionState | void | null> | undefined => {
        if (getAndroidQuickViewAPI()?.getConnectionStatusAsync) {
            return new Promise((resolve, reject) => {
                const requestId = getNextRequestId();
                window.AndroidCallbacks[requestId] = { resolve, reject };
                getAndroidQuickViewAPI()?.getConnectionStatusAsync?.(
                    `AndroidCallbacks[${requestId}].resolve`,
                    `AndroidCallbacks[${requestId}].reject`
                );
            }).then((connectionStatus: any) => {
                new Promise((resolve, reject) => {
                    try {
                        const connectionState: ConnectionState = JSON.parse(connectionStatus);
                        resolve(connectionState);
                    } catch (exception) {
                        reject(exception);
                    }
                });
            });
        } else if (getiOSAPI()) {
            return getiOSAPI()?.getConnectionStatus?.postMessage({}).then(
                it => { return { connectionState: getConnectionStateAsString(it?.connectionState) }; }
            );
        }
    },
    downloadBook: (asin: string, type: CONTENT_TYPE = CONTENT_TYPE.EBOK, title?: string): Promise<string | null> | undefined => {
        if (getAndroidQuickViewAPI()?.downloadBookAsync) {
            return new Promise((resolve, reject) => {
                const requestId = getNextRequestId();
                window.AndroidCallbacks[requestId] = { resolve, reject };
                getAndroidQuickViewAPI()?.downloadBookAsync?.(
                    asin,
                    type,
                    `AndroidCallbacks[${requestId}].resolve`,
                    `AndroidCallbacks[${requestId}].reject`
                );
            });
        } else if (getAndroidQuickViewAPI()?.downloadBook) {
            return new Promise((resolve) => {
                getAndroidQuickViewAPI()?.downloadBook(asin, type);
                resolve("downloadBook");
            });
        } else if (getiOSAPI()) {
            return getiOSAPI()?.downloadBook?.postMessage({
                asin,
                type,
                title,
            });
        }
    },
    fetchMoreData: (pagingToken?: string): Promise<PagingResult> | undefined => {
        if (getiOSAPI()?.fetchMoreData) {
            return getiOSAPI()?.fetchMoreData?.postMessage({
                pagingToken: pagingToken
            });
        }
    },
    launchQuickView: (asins: string[], initialIndex: number, args?: {title?: string, reftag?: string}): Promise<void> | undefined => {
        if (getiOSAPI()?.launchQuickView) {
            return getiOSAPI()?.launchQuickView?.postMessage({
                asins: asins,
                initialIndex: initialIndex,
            });
        }
        if (getAndroidAPI()?.launchQuickViewIfEnabledAsync) {
            return getAndroidAPI()?.launchQuickViewIfEnabledAsync(initialIndex, JSON.stringify(asins), args?.title, args?.reftag)
        }
    },
    getBatchBookOwnership: (asins: string[]) : Promise<BatchBookOwnershipResponse | null> | undefined => {
        if (getiOSAPI()) {
            return getiOSAPI()?.getBatchBookOwnership?.postMessage({ asins });
        }
        return undefined;
    },
    getBookStatus: (asin: string, type: CONTENT_TYPE = CONTENT_TYPE.EBOK): Promise<BookStatus | null> | undefined => {
        if (debug.get("disableGetBookStatus")) {
            return Promise.resolve(DEFAULT_BOOK_STATUS);
        }
        if (getAndroidQuickViewAPI()) {
            if (getAndroidQuickViewAPI()?.getBookStatusAsync) {
                return new Promise((resolve, reject) => {
                    const requestId = getNextRequestId();
                    window.AndroidCallbacks[requestId] = { resolve, reject };
                    getAndroidQuickViewAPI()?.getBookStatusAsync(
                        asin,
                        type,
                        `AndroidCallbacks[${requestId}].resolve`,
                        `AndroidCallbacks[${requestId}].reject`
                    );
                }).then((androidBookStatus: any) => {
                    return new Promise((resolve, reject) => {
                        if (!androidBookStatus) {
                            reject("Error in getting android bookStatus");
                            return;
                        }
                        let bookStatus: BookStatus = DEFAULT_BOOK_STATUS;
                        try {
                            const androidBookStatusJSON = JSON.parse(androidBookStatus);
                            bookStatus = {
                                bookState: mapContentStateToBookState(androidBookStatusJSON.bookState),
                                downloadProgress: androidBookStatusJSON.downloadProgress / 100,
                            };
                        } catch (exception) {
                            reject(exception);
                        } finally {
                            resolve(bookStatus);
                        }
                    });
                });
            } else if (getAndroidQuickViewAPI()?.getBookStatus) {
                return new Promise((resolve, reject) => {
                    let bookStatus: BookStatus = DEFAULT_BOOK_STATUS;
                    try {
                        const androidBookStatus = getAndroidQuickViewAPI()?.getBookStatus(asin, type);
                        if (!androidBookStatus) {
                            reject("Error in getting android bookStatus");
                            return;
                        }
                        const androidBookStatusJSON = JSON.parse(androidBookStatus);
                        bookStatus = {
                            bookState: mapContentStateToBookState(androidBookStatusJSON.bookState),
                            downloadProgress: androidBookStatusJSON.downloadProgress / 100,
                        };
                    } catch (exception) {
                        reject(exception);
                    } finally {
                        resolve(bookStatus);
                    }
                });
            }
        } else if (getiOSAPI()) {
            return getiOSAPI()?.getBookStatus?.postMessage({
                asin,
                type,
            });
        }
    },

    /**
     * Note: only currently supported on Android
     * @returns theme name (`"light"` or `"dark"`) or `null` if API unavailable
     */
    getThemeName: (): string | undefined => {
        return getAndroidAPI()?.getThemeName?.();
    },
    reportFastMetric: (schemaName: string, schemaVersion: number, payload: any): void => {
        if (getAndroidAPI()?.reportFastMetricAsync) {
            getAndroidAPI()?.reportFastMetricAsync(schemaName, schemaVersion, JSON.stringify(payload));
        } else if (getAndroidAPI()?.reportFastMetric) {
            getAndroidAPI()?.reportFastMetric(schemaName, schemaVersion, JSON.stringify(payload));
        } else if (getiOSAPI()) {
            getiOSAPI()?.reportFastMetric.postMessage({
                schemaName: schemaName,
                schemaVersion: schemaVersion,
                payload: payload,
            });
        }
    },
    reportBatchFastMetrics: (metrics: BatchedMetric[]): void => {
        if (getiOSAPI()) {
            getiOSAPI()?.reportBatchFastMetrics?.postMessage({ metrics });
        }
    },
    didFail: (message?: string): boolean => {
        if (getiOSAPI()?.didFail) {
            getiOSAPI()?.didFail?.postMessage({ message });
            return true;
        }
        return false;
    },
    reportActionMetric: (action: string, actionType: string): void => {
        if (getAndroidAPI()?.reportActionMetric) {
            getAndroidAPI()?.reportActionMetric(action, actionType);
        } else if (getiOSAPI()) {
            getiOSAPI()?.reportActionMetric.postMessage({
                action: action,
                actionType: actionType,
            });
        }
    },
    dismissQuickView: (finalIndex?: number): void => {
        if (typeof finalIndex === "number" && getAndroidQuickViewAPI()?.dismissQuickViewAtIndexAsync) {
            getAndroidQuickViewAPI()?.dismissQuickViewAtIndexAsync(finalIndex);
        } else if (getAndroidQuickViewAPI()?.dismissQuickViewAsync) {
            getAndroidQuickViewAPI()?.dismissQuickViewAsync();
        } else if (getAndroidQuickViewAPI()?.dismissQuickView) {
            getAndroidQuickViewAPI()?.dismissQuickView();
        } else if (getiOSAPI()?.dismissQuickView) {
            getiOSAPI()?.dismissQuickView.postMessage({ finalIndex });
        }
    },
    syncLibrary: (): void => {
        if (getiOSAPI()) {
            getiOSAPI()?.syncLibrary?.postMessage({});
        }
    },
    updateQuickViewPosition: (index: number): void => {
        if (getAndroidQuickViewAPI()?.updateQuickViewPosition) {
            getAndroidQuickViewAPI()?.updateQuickViewPosition(index);
        } else if (getiOSAPI()?.updateQuickViewPosition) {
            getiOSAPI()?.updateQuickViewPosition.postMessage({ index });
        }
    },
    httpRequest: (args: HttpRequestArgs): Promise<string | null> => {
        const path = args.path;
        const method = args.method ?? "GET";
        const headers = args.headers;
        const queryParams = args.queryParams;
        const body = args.body;
        const preferFetch = args.preferNative !== true;

        const iOSAPI = getiOSAPI();
        if (iOSAPI?.httpRequest) {
            return iOSAPI.httpRequest.postMessage({
                path,
                method,
                queryParams,
                headers,
                body,
            });
        }

        if (preferFetch && (path.startsWith("https:") || (location.protocol === "https:" && !location.hostname.endsWith("dev")))) {
            const urlPath = path.startsWith("https:") ? path : `${location.origin}${path}`;
            return fetch(setQueryParams(urlPath, queryParams), {
                credentials: "include",
                mode: "cors",
                method,
                headers,
                body,
            }).then((response) => response.text());
        }

        const androidAPI = getAndroidAPI();
        if (androidAPI?.httpRequestAsync) {
            return new Promise((resolve, reject) => {
                const requestId = getNextRequestId();
                window.AndroidCallbacks[requestId] = { resolve, reject };
                androidAPI.httpRequestAsync?.(
                    path,
                    method,
                    JSON.stringify(queryParams) ?? "",
                    JSON.stringify(headers) ?? "",
                    JSON.stringify(body) ?? "",
                    `AndroidCallbacks[${requestId}].resolve`,
                    `AndroidCallbacks[${requestId}].reject`
                );
            });
        } else if (androidAPI?.httpRequest) {
            return androidAPI.httpRequest(
                path,
                method,
                JSON.stringify(queryParams) ?? "",
                JSON.stringify(headers) ?? "",
                JSON.stringify(body) ?? ""
            );
        }

        // Fall back to regular fetch request when native APIs are unavailable.
        // During local development use a relative path for webpack-dev-server proxying
        // to avoid CORS issues when making requests to amazon.com from localhost
        const domain = (location.protocol === "http:")
            ? ""
            : getCurrentBaseUrl();

        return fetch(setQueryParams(`${domain}${path}`, queryParams), {
            credentials: "include",
            mode: "cors",
            method,
            headers,
            body,
        }).then((response) => response.text());
    },
    share: (shareData: ShareData): Promise<void> => {
        if (typeof navigator.share === "function") {
            return navigator.share(shareData);
        }
        return new Promise((resolve, reject) => {
            if (getAndroidQuickViewAPI()?.share) {
                getAndroidQuickViewAPI()?.share(shareData.title, `${shareData.text}\n${shareData.url}`);
                resolve();
            } else {
                reject();
            }
        });
    },
};

export const DEFAULT_BOOK_STATUS: BookStatus = {
    bookState: BookState.AZPluginBookStateInLibraryUnknown,
    downloadProgress: 0,
};

/**
 * Android Book Status as per https://tiny.amazon.com/8hxxf8uf/codeamazpackAndrblob2565libr
 * */
export enum ContentState {
    // an item for which all content(both required and optional) is local on the device
    LOCAL = "LOCAL",
    // an archive item in the cloud
    REMOTE = "REMOTE",

    // an item for which all required/deferred content is on the device, but some optional/preferred content is not
    REQUIRED_COMPLETE = "REQUIRED_COMPLETE",
    // an item that is openable, but doesnt have all required content for Jeffs plane ride.
    DOWNLOADING_OPENABLE = "DOWNLOADING_OPENABLE",
    // an archive item that's currently being downloaded
    DOWNLOADING = "DOWNLOADING",
    // an archive item that's currently queued for downloading
    QUEUED = "QUEUED",

    // failed to download but will be retried (wifi reconnect, etc).
    FAILED_RETRYABLE = "FAILED_RETRYABLE",
    // failed to download some of the assets yet openable, but will be retried
    FAILED_OPENABLE = "FAILED_OPENABLE",
    // failed to download and won't be retried
    FAILED = "FAILED",

    // some sideload txt file need a long time to convert the encoding.
    CONVERTING = "CONVERTING",
    CONVERSION_FAILED = "CONVERSION_FAILED",

    // Main content is paused
    PAUSED = "PAUSED",
}

/**
 * Mapping Android bookStatus to iOS book Status
 * @param bookStatus
 */
function mapContentStateToBookState(contentState: ContentState): BookState {
    let bookState = BookState.AZPluginBookStateInLibraryUnknown;
    switch (contentState) {
        case ContentState.DOWNLOADING:
        case ContentState.QUEUED:
        case ContentState.REQUIRED_COMPLETE:
        case ContentState.FAILED_RETRYABLE:
        case ContentState.CONVERTING:
            bookState = BookState.AZPluginBookStateInLibraryDownloading;
            break;
        case ContentState.LOCAL:
        case ContentState.DOWNLOADING_OPENABLE:
        case ContentState.FAILED_OPENABLE:
            bookState = BookState.AZPluginBookStateInLibraryOnDevice;
            break;
        case ContentState.FAILED:
        case ContentState.CONVERSION_FAILED:
            bookState = BookState.AZPluginBookStateInLibraryDownloadFailed;
            break;
        case ContentState.REMOTE:
            bookState = BookState.AZPluginBookStateInLibraryInCloud;
            break;
        case ContentState.PAUSED:
            bookState = BookState.AZPluginBookStateInLibraryDownloadBlocked;
    }
    return bookState;
}

/**
 * Read the TOS webview parameters available when loading within the Kindle app.
 * These values can be used to get the theme, locale, etc.
 */
export function getStoreWebviewParameters(): KindleStoreParametersI | undefined {
    const storeCookieValue = getCookieValue(KINDLE_STORE_COOKIE_NAME);

    if (!storeCookieValue) {
        return undefined;
    }

    return storeCookieValue
        .replace(/"/g, "")
        .split("&")
        .reduce((parameters: KindleStoreParametersI, parameter: string) => {
            const parts = parameter.split("=");

            if (parts.length === 2) {
                const key = parts[0] as keyof KindleStoreParametersI;
                parameters[key] = parts[1];
            }

            return parameters;
        }, {});
}
